// import "../styles/Footer.css";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";


const Footer = () => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.matchMedia('(max-width: 767px)').matches;
            setIsMobile(isMobile);
        };

        handleResize(); // Check initial viewport size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <footer className="bg-green">
            <div className="container-fluid">
                <section className="social-icons">
                    <ul className="nav justify-content-center">
                        {/* <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#"><i className="bi bi-discord"></i></a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link" href="https://twitter.com/nokkoNFT" target="_blank"><i className="bi bi-twitter-x"></i></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://www.instagram.com/nokko.club/" target="_blank"><i className="bi bi-instagram"></i></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://www.tiktok.com/@nokko.club" target="_blank"><i className="bi bi-tiktok"></i></a>
                        </li>
                    </ul>
                    <div className="copyright text-center">
                        <p>
                            &#169; 2023 <a href="https://middleclass.sg/" target="_blank">MIDDLECLASS.SG</a>
                        </p>
                    </div>
                </section>
                
            </div>
        </footer>
    );
}


export default Footer;
