import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ThirdwebProvider, metamaskWallet } from "@thirdweb-dev/react";
import { Ethereum, Goerli } from "@thirdweb-dev/chains";
// import { ethers } from "ethers";

// import $ from 'jquery';
import "./styles/globals.css";

// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
// import 'font-awesome/css/font-awesome.min.css';
// import "bootstrap/js/src/collapse.js";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// import 'bootstrap/dist/js/bootstrap.bundle';



// PAGES
import App from "./App";



// This is the chain your dApp will work on.
// Change this to the chain your app is built for.
// You can also import additional chains from `@thirdweb-dev/chains` and pass them directly.

const blockchainNetwork = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

// const activeChain = "goerli"; // Not in use??

const client_id = process.env.REACT_APP_SDK_CLIENT_ID;
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    {blockchainNetwork === "goerli" ? (
      <ThirdwebProvider 
        supportedWallets={[
          metamaskWallet()
        ]}
        clientId={client_id}
        activeChain={Goerli}
        // signer={new ethers.providers.Web3Provider(window.ethereum).getSigner()}
        supportedChains={[Goerli]}>

        <App />
      </ThirdwebProvider>
    ) : (
      <ThirdwebProvider 
        supportedWallets={[
          metamaskWallet()
        ]}
        clientId={client_id}
        activeChain={Ethereum}
        // signer={new ethers.providers.Web3Provider(window.ethereum).getSigner()}
        supportedChains={[Ethereum]}>

        <App />
      </ThirdwebProvider>
    )}    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
