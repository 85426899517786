import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./styles/ProductGrid.css";
import { Container } from 'react-bootstrap';
import Select from "react-select";
import getSymbolFromCurrency from 'currency-symbol-map'

import { getProducts, getTags } from './api/products'


export default function Products({ currency, handleCurrencyChange }: any) {

	const [products, setProducts] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [tags, setTags] = useState<any[]>([]);

	const [isMobile, setIsMobile] = useState(false);
	const [filterType, setFilterType] = useState({ value: '', label: 'Filter' });
	const [sortType, setSortType] = useState({ value: '', label: 'Sort' });

	useEffect(() => {
		const handleResize = () => {
			const isMobile = window.matchMedia('(max-width: 767px)').matches;
			setIsMobile(isMobile);
		};

		handleResize(); // Check initial viewport size
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	// const [currencyCode, setCurrencyCode] = useState<any>('USD');
	// const [rates, setRates] = useState([]);s


	useEffect(() => {
		setIsLoading(true);
		const fetchProductsData = async () => {
			try {
				const productsData = await getProducts(filterType.value, sortType.value);
				setProducts(productsData);
				setIsLoading(false);
				// console.log('productsData', productsData)

			} catch (error) {
				console.error('Error fetching products:', error);
			}
			
		};

		fetchProductsData();
	}, [filterType, sortType]);

	useEffect(() => {
		// setIsLoading(true);
		const fetchProductTags = async () => {
			try {
				const tagsData = await getTags();
				
				const tagsArray = tagsData.map((tags: any) => ({ value: encodeURIComponent(tags), label: tags }));

				setTags((tag) => [
					{value: '', label: 'All'},
					...tagsArray
				
				  ]);
			} catch (error) {
				console.error('Error fetching products:', error);
			}
			// setIsLoading(false);
		};

		fetchProductTags();
	}, []);


	function priceConversion(price: any) {
		// const currencyRate = rates.filter((r:any) => r.value === currency.value)
		const fromCent = parseFloat(price) / 100;
		const newPrice = fromCent * currency.rate;
		// const truncatedNumber = Math.floor(newPrice * 100) / 100;
		return newPrice.toFixed(2);
	}

	if (isLoading) {
		return (
			<Container fluid className="spinner-container" style={{ backgroundColor: "#FCF9EE" }}>
				{/* Loading... */}
				<div className="spinner-border" role="status">
					{/* <span className="visually-hidden">.</span> */}
				</div>
			</Container>

		);
	}

	const sortingOptions = [
		// { value: '', label: 'Sort' },
		{ value: 'asc', label: 'Ascending' },
		{ value: 'desc', label: 'Descending' }

	]

	const filterProducts = async (type: any) => {
		// console.log('type', type);
		setFilterType(type)

	}
	const sortProducts = async (type: any) => {
		// console.log('type', type);
		setSortType(type)

	}

	function getImageSrc(product:any){
		// console.log('type', type);
		// setSortType(type)
		let src = '';
		if ( product.custom_images.length !== 0 ) {
			let custom_src = product.custom_images.find((image: any) => Boolean(Number(image.is_default)))?.src;
			if (custom_src) {
				src = custom_src;
			} else {
				src = product.custom_images[0].src;
			}
		} else {
			src = product.images.find((image: any) => image.is_default)?.src
		}

		// console.log('src', src)
		return src;

	}



	return (
		<>
			<header className='bg-flesh'>
				<div className='d-flex justify-content-center'>
						<h3 className='ps-2'>
							nokko&trade; merch
						</h3>

					</div>
            </header>
			<div className="the-merch">
				<div className="">
					<div className='store-wrap'>
						<div className='filter-banner container-fluid'>
							<div className='d-flex flex-row justify-content-between'>
								<div>
									<Select
										className="filter-select"
										options={tags}
										isSearchable={false}
										placeholder="Filter"
										onChange={filterProducts}
										value={filterType}
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: 'transparent',
												backgroundColor: 'transparent',
												color: 'red'
											}),
											placeholder: (provided, state) => ({
												...provided,
												color: "#006F56"
											})
										}}
									/>
								</div>
								<div className='align-self-center'>
									{products?.length} product(s)
								</div>
								<div>
									<Select
										className="filter-select"
										options={sortingOptions}
										isSearchable={false}
										placeholder="Sort"
										onChange={sortProducts}
										value={sortType}
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: 'transparent',
												backgroundColor: 'transparent',
												neutral50: "red"
											}),
											placeholder: (provided, state) => ({
												...provided,
												color: "#006F56"
											})
										}} />
								</div>

							</div>

						</div>

						{/* </div> */}
						<div className='generic'>
							<div className='container-fluid'>
								{products.length > 0 ? (
									<div className="row pt-5">

										{products.map((product, index) => (

											<div className="col-lg-4 col-md-4 col-sm-6 d-flex" key={index}>

												<div className="card w-100 my-2 shadow-2-strong product-card">
													<Link to={`/store/${product.id}`} style={{ textDecoration: 'none' }}>
														<img src={getImageSrc(product)} className="card-img-top" alt="" />
														<div className="card-body d-flex flex-column">
															<h5 className="mb-1 me-1 card-text">{product.title}</h5>
															<p className=" price-text">{getSymbolFromCurrency(currency.value)}{priceConversion(product.variants.find((obj: any) => Boolean(Number(obj.is_enabled))).price)}
															</p>

														</div>
													</Link>
												</div>


											</div>


										))}
									</div>
								) : null

								}
							</div>
						</div>
						




					</div >
				</div>
			</div>
			
		</>
	);
}