import React, { useEffect, useState } from 'react';
import Slider from "react-slick";

import "./../styles/Carousel.css";

const Carousel = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.matchMedia('(max-width: 580px)').matches;
            setIsMobile(isMobile);
        };

        handleResize(); // Check initial viewport size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // if (isMobile) {
        //     var settings = {
        //         dots: false,
        //         infinite: true,
        //         speed: 1000,
        //         slidesToShow: 1.5,
        //         slidesToScroll: 1,
        //         adaptiveHeight: true,
        //         autoplay: true,
        //         centerMode: true

        //     };
        //     setSettings(settings);
        // } else {
        //     var settings = {
        //         dots: false,
        //         infinite: true,
        //         speed: 1000,
        //         slidesToShow: 6,
        //         slidesToScroll: 1,
        //         adaptiveHeight: true,
        //         autoplay: true,
        //         centerMode: false,

        //     };
        //     setSettings(settings);
        // }
        var settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 6,
            slidesToScroll: 1,
            adaptiveHeight: true,
            autoplay: true,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false
                    }
                }
            ]
        };
        setSettings(settings);
    }, [isMobile]);


    return (
        <Slider {...settings} >
            {Array.from({ length: 10 }, (_, index) => (
                <div className="pe-2" key={index+1}>
                    <img style={{ height: '300px', width: '210px' }} src={'/images/nft-cards/nft_' + (index + 1) + '.png'} alt={'nokko #0' + (index + 1)} />
                </div>
            ))}
        </Slider>
    );
}

export default Carousel;
