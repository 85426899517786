import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import "./styles/Home.css";
import React, { useEffect, useState } from 'react';
import { getOwnedNft, getSummary } from "./api/nfts";
import { useContract, useAddress, useStorage, useContractEvents, useWallet } from "@thirdweb-dev/react";

import 'react-toastify/dist/ReactToastify.css';

// import { login } from "./api/auth";

import waveloop from "./images/waveloop.gif";

export default function HomePage({ handleNewToken, tokenIds }: any) {

    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
    const [clicked, setClicked] = useState(false);
    const address = useAddress();
    const connected = useWallet();
    const storage = useStorage();
    const { contract: nftCollection } = useContract(
        process.env.REACT_APP_PUBLIC_NFT_COLLECTION_ADDRESS
    );

    const { data, error } = useContractEvents(nftCollection);

    // const  nfts  = useLoaderData() as NFTs[];
    // const [randomUnmintedNft, setRandomUnmintedNft] = useState<any>([] as NFTs[] | []);
    // const [nftMetadata, setNftMetadata] = useState<any>([] as NFTs[] | []);
    const [showModal, setShowModal] = useState(false);
    const [showProgressModal, setShowProgressModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    // const totalPages = Math.ceil(unmintedNft.total / 12);
    const [progress, setProgress] = useState(0);
    const [filterType, setFilterType] = useState({ value: 'all', label: 'All' });

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMsgTitle, setErrorMsgTitle] = useState('');
    // const [errorMsg, setErrorMsg] = useState('');
    const [newToken, setNewToken] = useState<any>();
    const [summary, setSummary] = useState<any>();



    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.matchMedia('(max-width: 767px)').matches;
            setIsMobile(isMobile);
        };

        handleResize(); // Check initial viewport size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, []);

    useEffect(() => {

        setIsLoading(true);
        const get = async () => {
            const summary = await getSummary();
            // console.log('setSummary', summary)

            if (!summary.error) {
                setSummary(summary.summary);
            }

            setIsLoading(false);
        };
        get();
    }, [newToken]);

    useEffect(() => {
        if (address && connected) {
            const getNftTokenId = async () => {
                const owned: any = await getOwnedNft(address);
                // console.log('ownde', owned);
                if (owned.length !== 0) {
                    //   console.log('owndesssss', JSON.stringify(owned));
                    handleNewToken(owned)

                } else {
                    handleNewToken('empty')
                }

                // console.log('toke', tokenIds);



            }
            getNftTokenId();
        }



    }, [address, connected, newToken]);



    const simulateAsyncOperation = (duration: number) => {
        return new Promise<void>((resolve) => {
            const interval = setInterval(() => {
                // Simulate progress by incrementing 10% every 100ms
                setProgress((prevProgress) => Math.min(prevProgress + 10, 100));
            }, 100);

            setTimeout(() => {
                clearInterval(interval);
                resolve();
            }, duration);
        });
    };


    // const handleCheckWalletToMint = async () => {
    //     setErrorMsg('');
    //     let idsArray: any;
    //     if (tokenIds !== 'empty' && tokenIds !== undefined) {
    //         idsArray = tokenIds.map((item: any) => item.metadata.id);
    //     }

    //     const tokens = idsArray !== undefined ? idsArray : '';
    //     // console.log('tokens', tokens);
    //     const allowed = await checkWalletIfAllowedForMinting(address, tokens);

    //     if (!allowed.error) {
    //         // console.log('allowed', allowed);
    //         mintNft();
    //     } else {
    //         setErrorMsgTitle(allowed.msg_title);
    //         setErrorMsg(allowed.msg);
    //         setShowErrorModal(true);

    //     }
    // };

    // const handleCloseErrorModal = () => {
    //     setShowErrorModal(false);
    // };

    // const mintNft = async () => {

    //     setProgress(0);

    //     const response = await fetchUnmintedNFT(address);


    //     console.log('1', response);

    //     if (!response.error) {

    //         const randomNft = response;

    //         // console.log('2', randomNft);
    //         try {
    //             setShowProgressModal(true);
    //             // console.log('2', randomNft.metadata);
    //             // const uri = await storage?.upload(randomNft.metadata);
    //             // console.log('3', uri);
                
    //             setProgress(50);
                
    //             // const url = await storage?.resolveScheme(uri!);
    //             // console.log('4', url);
                
    //             setProgress(60);
                
    //             const response = await nftCollection?.call("mintNFT", [address, randomNft.ipfs_url_metadata, randomNft.id, randomNft.reveal], { value: ethers.utils.parseEther(randomNft.amount) });
                
    //             setProgress(90);
    //             // console.log('5', response);
    //             // randomNft.minted = true;
    //             const new_token_id = Number(response.receipt.events[0].args.tokenId._hex);
    //             setNewToken(new_token_id);

    //             // const res = await mintingNfts(randomNft.id, new_token_id, address);
    //             setProgress(100);
    //             setShowProgressModal(false);
    //             setShowModal(true);


    //             // alert('Succesfully minted NFT!');
    //         } catch (e) {
    //             console.log(e);
    //             // setErrorMsg(e.reason);
    //             setErrorMsg("An error has occured or you have an insufficient funds.");
    //             setShowErrorModal(true);
    //             // console.log("An error occurred trying to mint the NFT:", e);
    //             setShowProgressModal(false);
    //         }

    //     } else {
    //         // console.log('1', response);
    //         setErrorMsgTitle(response.msg_title);
    //         setErrorMsg(response.msg);
    //         setShowErrorModal(true);

    //     }



    // };

    // const handleShowModal = () => {
    //     setShowModal(true);
    // };

    // const handleCloseModal = () => {
    //     setShowModal(false);
    // };

    if (isLoading) {
        return (
            <Container fluid className="spinner-container">
                {/* Loading... */}
                <div className="spinner-border" role="status">
                    {/* <span className="visually-hidden">.</span> */}
                </div>
            </Container>

        );
    }



    return (
        <div>
            <section className="banner">
                <div className="container-fluid">
                    <div className="grass-bg"></div>
                    <div className="text-center">
                        <div className="jumbotron">
                            <img src={waveloop} className="img-fluid" alt="waveloop"></img>
                        </div>
                    </div>
                </div>    
            </section>
            <section className="bg-green content-links">
                <div className="container-fluid text-center">
                    <div className="row align-items-center homepage-content">
                        <div className="col-lg-4">
                            <a className="" href="/story">story</a>
                        </div>
                        <div className="col-lg-4">
                            <a className="" href="/store">merch</a>
                        </div>
                        <div className="col-lg-4">
                            <a className="" href="https://opensea.io/collection/nokko" target="_blank" rel="noreferrer">collectibles</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}