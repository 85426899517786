const api_key = process.env.REACT_APP_API_KEY;
const api_base_url = process.env.REACT_APP_API_BASE_URL;

export const saveContact = async (contact: any) => {

    const data = {
        "name": contact.name,
        "company_name": contact.companyName,
        "email": contact.email,
        "phone": contact.phone,
        "message": contact.message
    }
    const response = await fetch(api_base_url + `/api/contact/save`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${api_key}`,
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
    }
    const res = await response.json();
    return res;
};