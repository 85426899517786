import React, { useState, useEffect } from 'react';
import "./styles/OrderHistory.css";
import { Table, Modal, Button, Row, Container } from 'react-bootstrap';
import { getOrders, fetchOrder } from './api/orders';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map'

export default function OrderHistory({ walletId, currency, currencyRates }: any) {

    const [show, setShow] = useState(false);
    const [orders, setOrders] = useState<any[]>([]);
    const [order, setOrder] = useState<any>({});
    // const [items, setItems] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);

    const handleClose = () => {
        setShow(false)
        setIsModalLoading(false);
        setOrder({});
    };
    const handleShow = (order_item: any) => {
        // e.preventDefault();
        setIsModalLoading(true);
        // setOrder(order);
        setShow(true);
        getOrder(order_item);

        // console.log('ordersDatass', order)

    };

    const getOrder = async (order_item: any) => {

        try {
            const orderData = await fetchOrder(order_item.printifyOrderId, order_item.paymentTransactionId);
            setOrder({ ...orderData, ...order_item });
            // console.log('ordersDatass', orderData)
            setIsModalLoading(false)






        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }

    useEffect(() => {

        setIsLoading(true);
        const fetchOrders = async () => {
            try {
                const ordersData = await getOrders(walletId);
                setOrders(ordersData);
                // console.log('ordersData', ordersData)


            } catch (error) {
                console.error('Error fetching products:', error);
            }
            setIsLoading(false);
        };




        fetchOrders();




    }, [walletId]);


    // const calculateShipping = (price: any) => {
    //     const fromCent = parseFloat(price) / 100;
    //     const shippingRate = currencyRates.find((curr: any) => curr.value === order.currency)
    //     const shippingAmountRate = (currency.rate / shippingRate?.rate)
    //     const newShippingAmount = fromCent * shippingAmountRate;
    //     // setnewShippingAmount(newShippingAmount.toFixed(2));

    //     // console.log('couponAmountRate', couponAmountRate)
    //     // console.log('current', shippingAmountRate)

    //     return newShippingAmount.toFixed(2)
    // }

    // function priceConversion(price: any) {
    //     // console.log('pri', price);
    //     // const currencyRate = rates.filter((r:any) => r.value === currency.value)
    //     const fromCent = parseFloat(price) / 100;
    //     const newPrice = fromCent * currency.rate;
    //     // const truncatedNumber = Math.floor(newPrice * 100) / 100;
    //     return newPrice.toString();
    // }

    // const calculateTotalPriceConvertion = (orderItems: any) => {
    //     // const order = 
    //     let sum = 0;
    //     orderItems?.forEach((order: any) => {
    //         // console.log('order.metadata.price', order.metadata.price)
    //         const price = order.metadata.price / 100;
    //         const newPrice = price * currency.rate;
    //         sum += parseFloat(newPrice.toFixed(2)) * order.quantity;
    //     });

    //     // console.log('newprice', sum)
    //     // const truncatedNumber = Math.floor(sum * 100) / 100;

    //     // const final = sum ;
    //     return sum.toFixed(2);
    // }


    // calculate total amount with discount
    const calculateTotal = (totalAmount: any, couponCodeAmount:any) => {
        // console.log('breakd', breakdown);
        const excludeDiscount = Number(totalAmount) + Number(couponCodeAmount);
        // console.log('breakd', excludeDiscount);
        return excludeDiscount.toFixed(2);
    }



    if (isLoading) {
        return (
            <Container fluid className="spinner-container">
                {/* Loading... */}
                <div className="spinner-border" role="status">
                    {/* <span className="visually-hidden">.</span> */}
                </div>
            </Container>

        );
    }


    return (
        <>
            <header className='bg-flesh'>
				<div className='d-flex justify-content-center'>
						<h3 className='ps-2'>
                        My Order History
						</h3>

					</div>
            </header>
            <section className="generic">
                <div className="container-fluid">
                    
                    {orders.length > 0 ? (
                        <>

                            <div className='table-responsive pt-4'>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Order ID</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Total Amount</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((order, index) => (
                                            <tr key={index}>
                                                <td className="align-middle">{index + 1}</td>
                                                <td className="align-middle">{order.printifyOrderId}</td>
                                                <td className="align-middle" style={{ minWidth: '200px' }}>{moment(order.createdAt).format('LLL')}</td>
                                                <td className="align-middle">{getSymbolFromCurrency(order.currency)}{calculateTotal(order.totalAmount, order.couponCodeAmount)}</td>
                                                <td className="text-center"> <Button variant="primary" onClick={(e) => handleShow(order)}>
                                                    Details
                                                </Button></td>
                                            </tr>
                                        ))}


                                    </tbody>
                                </Table>

                            </div>

                            <Modal show={show} dialogClassName="modal-80w" onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <p className='m-0'>Order ID: {order.printifyOrderId}</p>


                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {isModalLoading && Object.keys(order).length === 0 ? (
                                        <Container className="spinner-container">
                                            {/* Loading... */}
                                            <div className="spinner-border" role="status">
                                                {/* <span className="visually-hidden">.</span> */}
                                            </div>
                                        </Container>

                                    ) : (
                                        <>
                                            <div>
                                                <p className='text-muted sub-title m-0'>Placed on {moment(order.created_at).format('LLL')}</p>
                                                <p className='text-muted sub-title'>Status: <span className={`badge ${order.status === 'canceled' ? "text-bg-danger" : "text-bg-primary"
                                                    }`}>{order.status}</span></p>
                                            </div>
                                            <Row className='equal-height'>
                                                <div className='col-lg-8 pb-3'>
                                                    <div className="card shadow-0 border" >
                                                        <div className="p-4">


                                                            {order.line_items?.map((item: any, index: any) => (
                                                                <div className="d-flex align-items-center mb-4" key={index}>
                                                                    <div className="me-3 position-relative">
                                                                        <img
                                                                            src={item.images}
                                                                            style={{ height: "96px", width: "96px" }}
                                                                            className="img-sm rounded border"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="">
                                                                        {/* <a href="#" className="nav-link"> */}
                                                                        {item.name}
                                                                        {/* </a> */}
                                                                        <div className="price text-muted">
                                                                            Total: {getSymbolFromCurrency(order.currency)}{(parseFloat((item.unit_amount.value)) * item.quantity).toFixed(2)} | {item.quantity}{" "}
                                                                            item(s)
                                                                        </div>

                                                                        <div
                                                                            className="price text-muted"
                                                                            style={{ fontSize: "12px" }}
                                                                        >
                                                                            {getSymbolFromCurrency(order.currency)}{item.unit_amount.value} / per item
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ))}






                                                        </div>
                                                    </div>
                                                </div>




                                                <div className='col-lg-4 '>
                                                    <div className="card shadow-0 border mb-3" >
                                                        <div className="p-4">
                                                            <h6 className="mb-3">Summary</h6>
                                                            <div className="d-flex justify-content-between">
                                                                <p className="mb-2">Subtotal:</p>
                                                                <p className="mb-2">{getSymbolFromCurrency(order.currency)}{(order.breakdown?.item_total ? order.breakdown.item_total.value : '0.00')}</p>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <p className="mb-2">Discount:</p>
                                                                <p className="mb-2 text-success">-{getSymbolFromCurrency(order.currency)}{(order.breakdown?.discount ? order.breakdown.discount.value : '0.00')}</p>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <p className="mb-2">Shipping cost:</p>

                                                                <p className="mb-2">+ {getSymbolFromCurrency(order.currency)}{(order.breakdown?.shipping ? order.breakdown.shipping.value : '0.00')}</p>
                                                            </div>
                                                            <hr />
                                                            <div className="d-flex justify-content-between">
                                                                <p className="mb-2">Total price:</p>
                                                                {getSymbolFromCurrency(order.currency)}{(order ? order.totalAmount : '0.00')}
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {/* <div className='col-lg-6'> */}
                                                    <div className="card shadow-0 border" >
                                                        <div className="p-4">

                                                            <p
                                                                className="m-0"
                                                                style={{
                                                                    fontStyle: "italic",
                                                                    textTransform: "capitalize",
                                                                }}
                                                            >
                                                                Shipping Address:
                                                            </p>
                                                            <p className="m-0" style={{ fontWeight: "bold" }}>
                                                                <i className="bi bi-geo-alt pe-1"></i>
                                                                {(order.address_to ? order.address_to.first_name : '')} {(order.address_to ? order.address_to.last_name : '')}
                                                            </p>
                                                            <p className="m-0 text-muted">{(order.address_to ? order.address_to.email : '')}</p>
                                                            <p className="m-0">{(order.address_to ? order.address_to.phone : '')}</p>
                                                            <p className="m-0">{(order.address_to ? order.address_to.address1 : '')},</p>
                                                            <p className="m-0">
                                                                {(order.address_to ? order.address_to.city : '')} {(order.address_to ? order.address_to.zip : '')},
                                                            </p>
                                                            <p className="m-0">{(order.address_to ? order.address_to.country : '')} </p>

                                                        </div>
                                                    </div>

                                                    {/* </div> */}





                                                </div>


                                            </Row>
                                        </>

                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>

                    ) : (
                        <div>


                            <p className='pt-3'>No order history yet!</p>

                        </div>
                    )}


                </div>
            </section>
            
        </>


    );
}