import { ThirdwebSDK } from "@thirdweb-dev/sdk/evm";
import { MetaMaskWallet } from "@thirdweb-dev/wallets";

interface Attribute {
	trait_type: string;
	value: string;
}

export interface NFTs {
	metadata: {
		token_id: number;
		name: string;
		description: string;
		image: string;
		attributes: Attribute[];
	};
	id: string;
	price: number;
	minted?: boolean;
	revealed: boolean;
	reveal_time: number;
	placeholder: string;
	traits: string[];
}

const api_base_url = process.env.REACT_APP_API_BASE_URL;

const client_id = process.env.REACT_APP_SDK_CLIENT_ID;

const api_key = process.env.REACT_APP_API_KEY;

const blockchain_network:string = process.env.REACT_APP_BLOCKCHAIN_NETWORK!;

export const fetchOwnedNFTs = async (address: any, token_ids: any) : Promise<NFTs[]>=> {

	const response = await fetch(api_base_url + `/api/nfts/get/owned-nft?token_ids=` + token_ids,{
		headers: {
		  'Authorization': `Bearer ${api_key}`,
		},
	});

	if (!response.ok) {
		throw new Error(`API request failed with status ${response.status}`);
	}
	const data = await response.json();
	const nftsOwned = data.nfts;

	const res:any = [];

	const sdk = new ThirdwebSDK(blockchain_network, {
		clientId: client_id, // Use secret key if using on the server, get it from dashboard settings
	  });
	
	const nftCollection = await sdk.getContract(process.env.REACT_APP_PUBLIC_NFT_COLLECTION_ADDRESS!);

	try {
		
		if (nftsOwned.length === 0) {
			res['nfts'] = []
			return res;
		}
		
		const nfts: NFTs[] = await Promise.all(nftsOwned.map(async (nft: any) => {
			const isRevealed = await nftCollection?.call("isRevealed", [nft.token_id]);

			if(isRevealed){
				return {
					metadata: {
						token_id: nft.token_id,
						name: nft.name,
						description: nft.description,
						image: nft.ipfs_url ? ('ipfs://' + nft.ipfs_url.split(',')[0]) : null,
					},
					id: nft.id,
					price: nft.amount,
					revealed: isRevealed,
				}
				
			} else {
				return {
					metadata: {
						token_id: nft.token_id,
						name : "Coming Soon",
						description : "This NFT will be revealed soon!",
						image : "https://nokko.infura-ipfs.io/ipfs/QmdafmAsENkt5zoRHoNEaE3o2xFtZVP9cN3J9Z243PcF9C"
					},
					id: nft.id,
					price: nft.amount,
					revealed: isRevealed,
				}
			}

		}));

		res['nfts'] = nfts;
		return res;
	} catch (error) {
		console.error(error);
		throw error;
	}

}


export const fetchNFTs = async (page_number: any, type: any): Promise<NFTs[]> => {
	const response = await fetch(api_base_url + `/api/nfts/12?page=` + page_number, {
		headers: {
		  'Authorization': `Bearer ${api_key}`,
		},
	  });
	if (!response.ok) {
		throw new Error(`API request failed with status ${response.status}`);
	}
	const data = await response.json();
	const res: any = [];

	const sdk = new ThirdwebSDK(blockchain_network, {
		clientId: client_id, // Use secret key if using on the server, get it from dashboard settings
	  });
	
	const nftCollection = await sdk.getContract(process.env.REACT_APP_PUBLIC_NFT_COLLECTION_ADDRESS!);

	if (data.nfts.length === 0) {
		res['nfts'] = []
		return res;
	}

	const nfts: NFTs[] = data.nfts.items.map((nft: any) => {

		const attributesArray: Attribute[] = nft.traits.map((trait: any) => ({
			trait_type: trait.type,
			value: trait.value,
		}));

		return {
			metadata: {
				token_id: nft.token_id,
				name: nft.name,
				description: nft.description,
				image: nft.ipfs_url ? ('ipfs://' + nft.ipfs_url.split(',')[0]) : null,
				attributes: attributesArray,// traits: nft.traits
			},
			id: nft.id,
			price: nft.amount,
			minted: false,
			revealed: false,
			reveal_time: 1679522400,
			placeholder: {
				name : "Coming Soon",
				description : "This NFT will be revealed soon!",
				image : "https://nokko.infura-ipfs.io/ipfs/QmdafmAsENkt5zoRHoNEaE3o2xFtZVP9cN3J9Z243PcF9C"
			},
		};
	});


	try {
		const mintedNfts = await nftCollection?.erc721.getAll();
		if (!mintedNfts) {
			res['nfts'] = []
			return res;
		}

		await Promise.all(
			mintedNfts.map(async (nft) => {
				const isRevealed = await nftCollection?.call("isRevealed", [nft.metadata.id]);

				const nftIndex = nfts.findIndex((nftItem: any) => nftItem.metadata.token_id && nftItem.metadata.token_id.toString() === nft.metadata.id);
				if (nftIndex !== -1) {
					const attributes: Attribute[] = nft.metadata.attributes as [];
					const metadata = {
						token_id: parseInt(nft.metadata.id),
						name: nft.metadata.name as string,
						description: nft.metadata.description!,
						image: nft.metadata.image!,
						uri: nft.metadata.uri,
						attributes: attributes
					};
					// nfts[nftIndex].id = nft.metadata.id;
					nfts[nftIndex].minted = true;
					nfts[nftIndex].revealed = isRevealed;
					// nfts[nftIndex].metadata = metadata;


				}

			})
		);

		if (type === 'revealed') {
			const filtered = nfts.filter((nfts: any) => nfts.revealed);
			res['nfts'] = filtered;
			// console.log('filtee', filtered);
		} else if (type === 'minted') {
			const filtered = nfts.filter((nfts: any) => nfts.minted);
			res['nfts'] = filtered;
		} else {
			const filtered = nfts.filter((nfts: any) => nfts.metadata.image != null);
			res['nfts'] = filtered;
		}

		// res.push(nfts);
		// res['nfts'] = nfts;
		// res['nfts']['length'] = count;		// res.push(data.nfts)
		res['nfts']['total'] = data.nfts['total'];

		return res;
	} catch (error) {
		console.error(error);
		throw error;
	}

	// return nfts;
};
export const mintingNfts = async (id: any, token_id: any, wallet_address: any) => {


	const data = {
		"id": id,
		"token_id": token_id,
		"minted": 1,
		"address": wallet_address
	}
	const response = await fetch(api_base_url + `/api/nfts/mint`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${api_key}`,
			//   'X-CSRF-TOKEN': csrftoken
		},
		body: JSON.stringify(data)
	});

	if (!response.ok) {
		throw new Error(`API request failed with status ${response.status}`);
	}
	const res = await response.json();
	return res;
};


export const searchNfts = async (searchNft: any): Promise<NFTs[]> => {

	const data = searchNft;
	const res: any = [];
	// const sdk = new ThirdwebSDK("goerli");
	const sdk = new ThirdwebSDK(blockchain_network, {
		clientId: client_id, // Use secret key if using on the server, get it from dashboard settings
	  });
	const nftCollection = await sdk.getContract(process.env.REACT_APP_PUBLIC_NFT_COLLECTION_ADDRESS!);

	if (data.length === 0) {
		res['nfts'] = []
		return res;
	}

	const nfts: NFTs[] = data.map((nft: any) => {

		return {
			metadata: {
				token_id: nft.token_id,
				name: nft.name,
				description: nft.description,
				image: nft.ipfs_url ? ('ipfs://' + nft.ipfs_url.split(',')[0]) : null,
			},
			id: nft.id,
			price: nft.amount,
			minted: false,
			revealed: false,
			reveal_time: 1679522400,
			placeholder: {
				name : "Coming Soon",
				description : "This NFT will be revealed soon!",
				image : "https://nokko.infura-ipfs.io/ipfs/QmdafmAsENkt5zoRHoNEaE3o2xFtZVP9cN3J9Z243PcF9C"
			},
		};
	});


	try {
		const mintedNfts = await nftCollection?.erc721.getAll();
		if (!mintedNfts) {
			res['nfts'] = []
			return res;
		}

		await Promise.all(
			mintedNfts.map(async (nft) => {
				const isRevealed = await nftCollection?.call("isRevealed", [nft.metadata.id]);

				const nftIndex = nfts.findIndex((nftItem: any) => nftItem.metadata.token_id && nftItem.metadata.token_id.toString() === nft.metadata.id);
				if (nftIndex !== -1) {
					const attributes: Attribute[] = nft.metadata.attributes as [];
					const metadata = {
						token_id: parseInt(nft.metadata.id),
						name: nft.metadata.name as string,
						description: nft.metadata.description!,
						image: nft.metadata.image!,
						uri: nft.metadata.uri,
						attributes: attributes
					};

					// nfts[nftIndex].id = nft.metadata.id;
					nfts[nftIndex].minted = true;
					nfts[nftIndex].revealed = isRevealed;
					nfts[nftIndex].metadata = metadata;


				}

			})
		);

		// res.push(nfts);
		res['nfts'] = nfts;
		// res['nfts']['length'] = count;		// res.push(data.nfts)
		// res['nfts']['total'] = data.nfts['total'];

		return res;
	} catch (error) {
		console.error(error);
		throw error;
	}

	// return nfts;
};



export const fetchUnmintedNFT = async (address:any) => {
	const response = await fetch(api_base_url + `/api/nfts/get?wallet_address=` + address, {
		headers: {
		  'Authorization': `Bearer ${api_key}`,
		},
	  });
	if (!response.ok) {
		throw new Error(`API request failed with status ${response.status}`);
	}
	const res = await response.json();
	if (res.nft) {
		// const attributesArray: Attribute[] = res.nft.traits.map((trait: any) => ({
		// 	trait_type: trait.type,
		// 	value: trait.value,
		// }));


		// const nft = {
		// 	error: res.error,
		// 	msg: res.msg,
		// 	msg_title: res.msg_title,
			
		// 	nft: {
		// 		metadata: {
		// 			name: res.nft.name,
		// 			description: res.nft.description,
		// 			image: res.nft.ipfs_url ? ('ipfs://' + res.nft.ipfs_url.split(',')[0]) : null,
		// 			attributes: attributesArray

		// 		},
		// 		reveal: res.nft.reveal,
		// 		id: res.nft.id,
		// 		price: res.nft.amount,
		// 	}
		// }
		return res.nft;
	}

	return res;

};


export const getOwnedNft = async (wallet_address: any) => {

	// const res: any = {}

	const sdk = new ThirdwebSDK(blockchain_network, {
		clientId: client_id, // Use secret key if using on the server, get it from dashboard settings
	  });
	const nftCollection = await sdk.getContract(process.env.REACT_APP_PUBLIC_NFT_COLLECTION_ADDRESS!);


	try {
		//   const mintedNfts = await nftCollection?.erc721.getAll();
		// const mintedOwnedNft = await nftCollection?.erc721.getOwned(wallet_address);
		const mintedOwnedNft = await nftCollection?.erc721.getOwnedTokenIds(wallet_address);

		if (mintedOwnedNft.length == 0) {
			const res: any[] = [];
			return res;
		}

		// res.push(nfts);
		const res = mintedOwnedNft;

		return res;
	} catch (error) {
		console.error(error);
		throw error;
	}

	// return nfts;
};

export const getSummary = async () => {

    const response = await fetch(api_base_url + `/api/nfts/summary`, {
		headers: {
		  'Authorization': `Bearer ${api_key}`,
		}});

    if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
    }
    const res = await response.json();
    return res;
};



// export const connectWallet = async () => {
// 	// This is the message to be signed
// 	const message = "Sign this message...";
// 	const sdk = new ThirdwebSDK("goerli");

// // Now we can sign the message with the connected wallet
// 	const signature = await sdk.wallet.sign(message);
// };