import React from 'react';

import nokkoMeet from './images/nokko-meet.png';
import nokkoTraits from './images/nokkos-traits.png';
import nokkoFace from './images/nokko-face.png';
import { ConnectWallet } from '@thirdweb-dev/react';
import Carousel from "./components/carousel";

export default function Story({ handleNewToken, tokenIds }: any) {
    const scrollingText = [
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',
        'our lovable brand mascot!',

    ];

    const carouselContent = [
        {
            title: 'Each nokko™ NFT is inclusive of:',
            class: 'carousel-item active',
            indicator: 'active',
            items: [
                'USD100 worth of nokko™ merchandise, featuring over 15 stylish items',
                'USD50 worth of exclusive discount codes for the fine stay portal, STAYFOLIO',
                'Limited edition nokko™ poker cards valued at USD39',
                'A captivating travel e-book highlighting the wonders of Seoul and Busan worth USD49',
                'A free beverage coupon redeemable at popular Korea coffee chains valued up to USD15',
                'nokko™ plush for 1/1 (Gold Frames) nokko™ NFT users, worth USD49',
                'Exclusive rights to the highly-anticipated launch of the next NFT series, with immediate whitelist access'
            ]
        },
        {
            title: 'Additionally, each nokko™ NFT gives you a chance to win exciting prizes:',
            class: 'carousel-item',
            indicator: '',
            items: [
                '1 LUCKY nokko™ holder will win a round-trip ticket to South Korea, worth up to USD3,000',
                '1 LUCKY nokko™ holder will enjoy a relaxing stay at the Good ol’ days Hotel in Busan, worth up to USD300',
                '1 LUCKY nokko™ holder will experience a luxurious stay at the beautiful nomore wander in Seoul, valued at USD250',
                '1 LUCKY nokko™ holder will get a pair of tickets for the Bukchon Hanok Village & Changdeokgung Palace Tour, valued at USD230',
                '1 LUCKY nokko™ holder will be treated to an Euljiro food tour, complete with a knowledgeable guide, valued at USD135',
                '1 LUCKY nokko™ holder will explore the unique flavors of Gwangjang Market with a pair of tickets for a food tour, worth up to USD130'
            ]
        }
    ]
    return (
        <>
            <header>
                <div className="text-center">
                    <img src={nokkoMeet} className="img-fluid" alt="nokko meet" />
                </div>
            </header>
            <section>
                <div className="scroll">
                    <div className="RightToLeft">
                        {scrollingText.map((scrollText, index) => (
                            <p><i className="bi bi-star-fill"></i> {scrollText}</p>
                        ))}      
                    </div>
                </div>
            </section>
            <section className="description">
                <div className="container-fluid">
                    <div className="row text-center">
                        <div className="col-md-6 description-col">
                            <img src={nokkoTraits} className="img-fluid" alt="nokko traits" />
                        </div>
                        <div className="col-md-6 description-col bg-orange">
                            <div className="row align-items-center h-100">
                                <div className="col-12">
                                    <img src={nokkoFace} className="img-fluid" alt="nokko faec" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="the-story">
                <div className="container-fluid">
                    <div className="story-container">
                        <h2 className="text-center mb-3">the story</h2>
                        <p className="mb-3">
                            once upon a time, nokko, the tail-less tiger, was determined to reclaim his glory and embarked on a quest across South Korea to find his lost pride, his tail! 
                        </p>
                        <p className="mb-3">
                            as he roamed through the country, savouring its cuisine, soaking in its culture, and basking in the warmth of its people, he had an epiphany! he realized that it was not his tail he truly longed for; it was the love he'd found for South Korea! 
                        </p>
                        <p className="mb-3">
                            sometimes, the greatest adventures are not about what you lose but about what you find along the way.
                        </p>
                        <p className="mb-3">
                            now, nokko wants to share his love for Korea with you! <i className="bi bi-heart-fill red"></i>
                        </p>
                    </div>
                </div>
            </section>
            <div className="nfts-collections-container bg-blue">
                <section className="bg-blue nft-collections-title">
                    <div className="container-fluid">
                        <h2 className="text-center mb-3">the NFT collection</h2>
                        <p className="mb-3">
                            from must-visit destinations for first-time travelers to lesser-known locales perfect for seasoned adventurers, nokko invites you to discover the vibrant essence of South Korea through this captivating collection of NFT trading cards!
                        </p>
                    </div>
                </section>

                <section className="bg-blue nft-collections-cards">
                    {/* <div className="container-fluid"> */}
                        <Carousel />
                    {/* </div> */}
                </section>
                <section className="bg-blue nft-collection-carousel">
                    <div className="container-fluid">
                        <div id="carouselExampleIndicators" className="carousel slide">
                        <div className="carousel-indicators">
                            {carouselContent.map((item, index) => (
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={item.indicator} aria-current="true" aria-label="Slide 1"></button>
                            ))}
                        </div>
                        <div className="carousel-inner">
                            {carouselContent.map((item, index) => (
                                <div className={item.class}>
                                    <div className="item-container">
                                        <h4>{item.title}</h4>
                                        <ul>
                                            {item.items.map((li, liIndex) => (
                                                <li>{li}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <i className="bi bi-arrow-left"></i>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <i className="bi bi-arrow-right"></i>
                        </button>
                        </div>
                    </div>
                </section>
                <section className="bg-blue connect-wallet-container">
                    <div className="container-fluid text-center">
                        {/* <a href="" className="btn-orange connect-wallet">connect wallet <i className="bi bi-wallet2"></i></a> */}
                        <ConnectWallet className="btn-orange connect-wallet" btnTitle="Connect Wallet"></ConnectWallet>
                    </div>
                </section>
            </div>
        </>
    );
}