import React, { useState } from 'react';
import { saveContact } from "./api/contacts";
import { Form } from "react-bootstrap";

import nokkoIconBlue from './images/nokko-icon-blue.png';

export default function Contact({ handleNewToken, tokenIds }: any) {
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [submitButton, setSubmitButton] = useState('Submit');
    const [error, setError] = useState(false);
    const [contactId, setContactId] = useState();
    const [messageError, setMessageError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
	const phoneNumberPattern = new RegExp('^[0-9+ -]{6,}$');
	const validatePhoneNumber = (number: any) => {
		if (phoneNumberPattern.test(number)) {
			setPhoneNumberError(false);
			return false;
		} else {
			setPhoneNumberError(true);
			return true;
		}
	};

    const validMessage = (event: any) => {
		if (event.target.value.length <= 500) {
			setMessageError(false);
            setMessage(event.target.value)
		} else {
			setMessageError(true);
            
		}
	};


    const handleSubmit = (e: any) => {
        e.preventDefault();
        setSubmitButton('Submitting...');
        setError(false);

        const inputData = {
            name: name,
            companyName: companyName,
            email: email,
            phone: phone,
            message: message
        };

        const isPhoneInvalid = validatePhoneNumber(phone);

        if(!isPhoneInvalid && !messageError){
            const saveContactDetails = async (contact: any) => {
                const res = await saveContact(contact);
                if (res) {
                    setError(res.error);
                    setContactId(res.id);
                }

                setSubmitButton('Submit');    
            };

            saveContactDetails(inputData);
        } else {
            setSubmitButton('Submit');
        }

    }

    return (
        <>
            <header className="bg-pink">
                <div className="container-fluid">
                    <p className="lets-chat">
                        <a href="/">
                            <i className="bi bi-arrow-left"></i> let's chat!
                        </a>
                    </p>
                </div>
            </header>
            
            <section className="contact-container bg-pink">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 text-end">
                            <div className="">
                                <span className="chat-bubble fade-in">
                                    Got something to say or wanna join forces? We’d love to hear from you!
                                </span>
                                <br />
                                <span className="chat-bubble fade-in-10">
                                    Drop us a message at <a href="mailto:hello@nokko.club">hello@nokko.club</a> 💌
                                </span>
                                <br />
                                <span className="chat-bubble fade-in-15">
                                    Or slide into our DMs
                                    <br />
                                    {/* <a href="#"><i className="bi bi-discord"></i></a> */}
                                    <a href="https://twitter.com/nokkoNFT" target="_blank"><i className="bi bi-twitter-x"></i></a>
                                    <a href="https://www.instagram.com/nokko.club/" target="_blank"><i className="bi bi-instagram"></i></a>
                                    <a href="https://www.tiktok.com/@nokko.club" target="_blank"><i className="bi bi-tiktok"></i></a>
                                </span>
                            </div>
                            <p className="fade-in-20">
                                <img src={nokkoIconBlue} className="img-fluid" alt="Nokko Icon Blue" />
                            </p>
                        </div>
                        <div className="col-md-6">
                            {error ? (
                                 <div className="alert alert-danger">
                                    <p>
                                        Your message has not been sent. Please try again.
                                    </p>
                                </div>

                            ) : null }

                            {!error && contactId ? (
                                 <div className="alert alert-success">
                                    <p>
                                        Your message has been sent successfully.
                                    </p>
                                </div>

                            ) :  null }
                           
                           
                            <Form onSubmit={(e) => handleSubmit(e)} className="contact-form">
                                <div className="mb-3">
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} required className="form-control" placeholder="Your Name*" />
                                </div>
                                <div className="mb-3">
                                    <input type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required className="form-control" id="" placeholder="Company Name*" />
                                </div>
                                <div className="mb-3">
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className="form-control" id="" placeholder="Email*" />
                                </div>
                                <div className="mb-3">
                                    <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} required className="form-control" id="" placeholder="Phone Number*" />
                                    {phoneNumberError ? (
                                    <div className="alert alert-danger mt-1 m-0 p-1">
                                        <p>
                                            <i className="bi bi-exclamation-circle pe-2"></i>
                                            Please enter a valid Phone Number. Must be at least 6 characters.
                                        </p>
                                    </div>
                                ) : null}
                                </div>
                                <div className="mb-3">
                                    <textarea value={message} onChange={(e) => validMessage(e)} required className="form-control" rows={8} placeholder="Type Something..."></textarea>
                                    {messageError ? (
                                        <div className="alert alert-danger mt-1 m-0 p-1">
                                            <p>
                                                <i className="bi bi-exclamation-circle pe-2"></i>
                                                Please enter a message that is no more than 500 characters.
                                            </p>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3 text-end">
                                    
                                    <button type="submit" className="btn btn-submit">{submitButton}</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}