import React from 'react';

import 'react-toastify/dist/ReactToastify.css';

import nokkoHeader from './images/nokko-header.png';
import cardBlue from './images/backgrounds/cards/blue.png';

import songyu from './images/team/songyu.png';
import ashley from './images/team/ashley.png';
import chin from './images/team/chin.png';
import christine from './images/team/christine.png';
import kirkrew from './images/team/kirkrew.png';
import mag from './images/team/mag.png';
import marcia from './images/team/marcia.png';
import nat from './images/team/nat.png';
import peter from './images/team/peter.png';
import weiting from './images/team/weiting.png';
import novie from './images/team/novie.png';

export default function Team({ handleNewToken, tokenIds }: any) {
    const teamMembers = [
        {
            name: 'songyu',
            koreanName: '송규',
            role: 'The Procrastinating Daydreamer',
            social: [
                {
                    class: 'bi bi-instagram',
                    url: 'https://instagram.com/__sy_g',
                },
                {
                    class: 'bi bi-tiktok',
                    url: 'https://tiktok.com/@songyu.goh',
                }
            ],
            profileImage: songyu,
            card: 'card',
            icon: 'card-icon'
        },
        {
            name: 'chin',
            koreanName: '친',
            role: 'The Starving Artist',
            social: [
                {
                    class: 'bi bi-instagram',
                    url: 'https://instagram.com/peashalpp',
                },
                {
                    class: 'bi bi-twitter-x',
                    url: 'https://twitter.com/peashalpp',
                }
            ],
            profileImage: chin,
            card: 'card orange-card',
            icon: 'card-icon chin'
        },
        {
            name: 'kirkrew',
            koreanName: '커크루',
            role: 'The 3D Man',
            profileImage: kirkrew,
            card: 'card green-card',
            icon: 'card-icon kirk',
            social: [
                {
                    class: 'bi bi-instagram',
                    url: 'https://www.instagram.com/kirkrew/',
                },
                {
                    class: 'bi bi-tiktok',
                    url: 'https://www.tiktok.com/@kirkrew',
                }
            ]
        },
        {
            name: 'maggie',
            koreanName: '메기',
            role: 'The Pixel Picasso',
            profileImage: mag,
            card: 'card pink-card',
            icon: 'card-icon maggie',
            social: [
                {
                    class: 'bi bi-instagram',
                    url: 'https://www.instagram.com/maggienatascha/',
                },
                {
                    class: 'bi bi-tiktok',
                    url: 'https://www.tiktok.com/@nutm4g?is_from_webapp=1&sender_device=pc',
                }
            ]
        },
        {
            name: 'Christine',
            koreanName: '크리스틴',
            role: 'The Hybrid Designer',
            profileImage: christine,
            card: 'card red-card',
            icon: 'card-icon christine',
            social: [
                {
                    class: 'bi bi-linkedin',
                    url: 'https://www.linkedin.com/in/chautranhoang/',
                },
            ]
        },
        {
            name: 'weiting',
            koreanName: '웨이팅',
            role: 'The Metaverse Fairy',
            profileImage: weiting,
            card: 'card blue-card',
            icon: 'card-icon weiting',
            social: [
                {
                    class: 'bi bi-instagram',
                    url: 'https://www.instagram.com/_chengweiting_/',
                },
                {
                    class: 'bi bi-twitter-x',
                    url: 'https://twitter.com/WEITINGG_98',
                },
            ]
        },
        {
            name: 'natalie',
            koreanName: '나탈리',
            role: 'The Seoul Seeker',
            profileImage: nat,
            card: 'card orange-card',
            icon: 'card-icon nat',
            social: [
                {
                    class: 'bi bi-instagram',
                    url: 'https://www.instagram.com/infinat/',
                },
                {
                    class: 'bi bi-twitter-x',
                    url: 'https://www.tiktok.com/@infinat',
                },
            ]
        },
        {
            name: 'marcia',
            koreanName: '마르시아',
            role: 'The Design Slave',
            profileImage: marcia,
            card: 'card green-card',
            icon: 'card-icon marcia',
            social: [
                {
                    class: 'bi bi-instagram',
                    url: 'https://www.instagram.com/awkwardred/',
                },
            ]
        },
        {
            name: 'ashley',
            koreanName: '애슐리',
            role: 'The Cat Mom',
            profileImage: ashley,
            card: 'card pink-card',
            icon: 'card-icon ashley',
            social: [
                {
                    class: 'bi bi-instagram',
                    url: 'https://www.instagram.com/a.shleu/',
                },
            ]
        },
        {
            name: 'peter',
            koreanName: '피터',
            role: 'The Stealth Programmer',
            profileImage: peter,
            card: 'card red-card',
            icon: 'card-icon peter',
            social: []
        },
        {
            name: 'novie',
            koreanName: '노비',
            role: 'The Code Valkyrie',
            profileImage: novie,
            card: 'card',
            icon: 'card-icon novie',
            social: [
                {
                    class: 'bi bi-instagram',
                    url: 'https://www.instagram.com/viejoy24/'
                }
            ]
        },
    ];

    return (
        <>
            <header className="bg-flesh">
                <div className="container-fluid">
                    <p className="text-center mb-3">
                        <img src={nokkoHeader} className="img-fluid" alt="nokko header" />
                    </p>
                    <div className="header-text">
                        <p className="mb-3">
                            behind nokko is a creative club of supercharged fangirls and fanboys whose hearts go 두근두근 for all things Korean! 🇰🇷
                        </p>
                        <p className="mb-3">
                            from K-entertainment, lifestyle, cuisine, travel, and everything in between, we're here to vibe with you, 24/7! 😛🫰
                        </p>
                        <p className="mb-3">
                            whether you're a seasoned stan or just dipping your toes into the Hallyu wave, we're here to be your besties and source of K-inspiration through our lovable tiger mascot, nokko! ✨
                        </p>
                    </div>
                </div>
            </header>
            <section className="team-container bg-flesh">
                <div className="container-fluid">
                    <div className="team-item">
                        {teamMembers.map((member, index) => (
                            <div className={member.card} key={index}>
                                <div className="card-bg-hidden">
                                    <img src={cardBlue} width="100%" alt="card blue" />
                                </div>
                                <div className={member.icon}></div>
                                <div className="card-details">
                                    <p className="name">
                                        {member.name}
                                    </p>
                                    <p className="korean-name">
                                        {member.koreanName}
                                    </p>
                                    <div className="profile-image">
                                        <img src={member.profileImage} className="img-fluid" alt={member.name} />
                                    </div>
                                    <p className="role">
                                        {member.role}
                                    </p>
                                </div>
                                <div className="card-barcode"></div>
                                {member.social && (
                                    <div className="card-social">
                                        <ul>
                                            {member.social.map((social, socialKey) => (
                                                <li key={socialKey}>
                                                    <a href={social.url} target="_blank" rel="noreferrer"><i className={social.class}></i></a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className="clearfix"></div>
                    </div>
                </div>
            </section>
        </>
    );
}